import React, { useEffect, useState } from "react";
import { tokens } from '../../../theme';
import { useTheme, Dialog, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import '../../visuals/design/skSpecial.css';
import Breakdown from "./Breakdown";

const CampaignsTableAll = (props) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const hexToRgba = (hex, opacity) => {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return `rgba(${r},${g},${b},${opacity})`;
    };

    const [loading, setLoading] = useState(true);
    const [loadingBackgroundColor, setLoadingBackgroundColor] = useState('lightblue');
    const [open, setOpen] = useState(false);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [campaigns, setCampaigns] = useState([]);
    const [totals, setTotals] = useState([]);
    const [sortOrder, setSortOrder] = useState("desc");
    const [sortClient, setSortClient] = useState("desc");
    const [sortImpressions, setSortImpressions] = useState("desc");

    useEffect(() => {
        const fetchCampaigns = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/campaign3`);
                const data = await response.json();
                if (response.ok) {
                    setCampaigns(data);
                }
            } catch (error) {
                console.error("Failed to fetch campaigns:", error);
            } finally {
                setLoading(false);
            }
        };


        const fetchTotals = async () => {
            setLoading(true);
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/campaign3/totals`);
                const data = await response.json();
                if (response.ok) {
                    setTotals(data);
                }
            } catch (error) {
                console.error("Failed to fetch campaigns:", error);
            } finally {
                setLoading(false);
            }
        }


        fetchCampaigns();
        fetchTotals();
    }, [props.holding]);

    // console.log(campaigns)

    const handleAssetClick = (campaignDetails) => {
        setSelectedBrand(campaignDetails);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const sortCampaigns = (campaigns, order) => {
        return campaigns.sort((a, b) => {
            if (order === "asc") {
                return String(a.live).localeCompare(String(b.live));
            } else {
                return String(b.live).localeCompare(String(a.live));
            }
        });
    };

    const toggleSortOrder = () => {
        const newSortOrder = sortOrder === "asc" ? "desc" : "asc";
        setSortOrder(newSortOrder);
        setCampaigns(sortCampaigns([...campaigns], newSortOrder));
    };

    const sortClientOrder = (campaigns, order) => {
        return campaigns.sort((a, b) => {
            if (order === "asc") {
                return String(a.holding).localeCompare(String(b.holding));
            } else {
                return String(b.holding).localeCompare(String(a.holding));
            }
        });
    };

    const toggleSortClientOrder = () => {
        const newClientOrder = sortClient === "asc" ? "desc" : "asc";
        setSortClient(newClientOrder);
        setCampaigns(sortClientOrder([...campaigns], newClientOrder));
    };

    const sortImpressionsOrder = (campaigns, order) => { // New function to sort by impressions
        return campaigns.sort((a, b) => {
            if (order === "asc") {
                return a.Imp_CampaignTotal - b.Imp_CampaignTotal;
            } else {
                return b.Imp_CampaignTotal - a.Imp_CampaignTotal;
            }
        });
    };

    const toggleSortImpressionsOrder = () => { // New function to toggle impressions sort order
        const newImpressionsOrder = sortImpressions === "asc" ? "desc" : "asc";
        setSortImpressions(newImpressionsOrder);
        setCampaigns(sortImpressionsOrder([...campaigns], newImpressionsOrder));
    };

    return (
        <div id="lato">
            <div>
                {loading ? (
                    <div style={{
                        backgroundColor: loadingBackgroundColor,
                        padding: '20px',
                        textAlign: 'left',
                        color: '#000',
                        fontSize: '14px'
                    }}>
                        Loading... please hold.
                    </div>
                ) : (
                    <>
                        <div style={{
                            marginBottom: '10px', backgroundColor: 'white', paddingLeft: '5px', width: '200px'
                        }}>Not all data is here yet.</div>


                        <div className="tally31" style={{ width: '250px' }} >

                            {/* IMP */}
                            < div style={{ borderColor: colors.SKcustom[10] }}>

                                <div style={{ margin: '0px', fontSize: '1.5em', fontWeight: '600', color: colors.SKcustom[17] }}>
                                    {(totals.AllImpressions ?? 0).toLocaleString()}
                                </div>
                                <div style={{ fontSize: '1.8em', fontWeight: 900, textTransform: 'uppercase', marginTop: '-10px', color: colors.SKcustom[10] }}>imps</div>
                            </div>

                            {/* VIEWS */}
                            < div style={{ borderColor: colors.SKcustom[10] }}>

                                <div style={{ margin: '0px', fontSize: '1.5em', fontWeight: '600', color: colors.SKcustom[17] }}>
                                    {(totals.AllViews ?? 0).toLocaleString()}
                                </div>
                                <div style={{ fontSize: '1.8em', fontWeight: 900, textTransform: 'uppercase', marginTop: '-10px', color: colors.SKcustom[10] }}>views</div>
                            </div>
                        </div>

                        <table style={{ width: '90%', borderCollapse: 'collapse', marginTop: '20px' }}>
                            <thead>
                                <tr style={{ color: colors.SKcustom[20] }}>
                                    <th className="campcolS" onClick={toggleSortOrder}>Launch {sortOrder === "asc" ? "▲" : "▼"}</th>
                                    <th className="campcolS" onClick={toggleSortClientOrder}>Client {sortClient === "asc" ? "▲" : "▼"}</th>
                                    <th className="campcolS">Brand</th>
                                    <th className="campcolS">Campaign</th>
                                    <th className="campcolS" onClick={toggleSortImpressionsOrder}>Impressions {sortImpressions === "asc" ? "▲" : "▼"}</th>
                                </tr>
                            </thead>
                            <tbody style={{ color: colors.SKcustom[10] }}>
                                {campaigns.filter(item => item.list === true) // Only include items with list: true
                                    .map((item, index) => (
                                        <tr key={index} style={{ cursor: 'pointer', backgroundColor: index % 2 === 0 ? hexToRgba(colors.SKcustom[25], 0.2) : 'transparent' }} onClick={() => handleAssetClick(item)}>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.live}</td>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.holding}</td>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.allBrands}</td>
                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.campaign}</td>

                                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{item.Imp_CampaignTotal.toLocaleString()}</td>


                                        </tr>
                                    ))}
                            </tbody>
                        </table>

                        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
                            <IconButton
                                onClick={handleClose}
                                style={{ position: 'absolute', right: '10px', top: '10px' }}
                            >
                                <CloseIcon />
                            </IconButton>
                            {selectedBrand && <Breakdown details={selectedBrand} />}
                        </Dialog>
                    </>
                )}
            </div>
        </div>
    );
};

export default CampaignsTableAll;
