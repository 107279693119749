import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";

import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import '../components/visuals/design/skSpecial.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import SNLogo from "../img/SN_whiteLogo.png"
import SNLogo2 from "../img/SN_goldLogo.png"

import SNsubscribers from "../components/SN/SNsubscribers";
import SnGA4 from '../components/SN/SnGA4';
// import SNcaseStudy from "../components/audience/SNcaseStudy";

import UsBc from "../components/SN/BigCommerce/UsBcUps"

const ReachSN = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [bcSum, setSum] = useState(0);
    useEffect(() => {
        const fetchSum = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/usup`);

            const data = await response.json()

            if (response.ok) {
                setSum(data)
            }
        };
        fetchSum();

    }, []);

    const formattedBcSum = bcSum.toLocaleString()
    const registeredMembers = bcSum

    //Klaviyo
    // const [klaviyo, setKlaviyo] = useState();
    // useEffect(() => {
    //     const fetchKlaviyo = async () => {
    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/klaviyo/active`);
    //             const data = await response.json();

    //             if (response.ok) {
    //                 setKlaviyo(data.profileCount);
    //             } else {
    //                 console.error('Response not ok:', data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching Klaviyo data:', error);
    //         }
    //     };
    //     fetchKlaviyo();
    // }, []);


    //Facebook
    // const [facebook, setFacebook] = useState();
    // useEffect(() => {
    //     const fetchFacebook = async () => {
    //         try {
    //             const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/meta/snfb`);
    //             const data = await response.json();

    //             if (response.ok) {
    //                 setFacebook(data);
    //             } else {
    //                 console.error('Response not ok:', data);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching Facebook data:', error);
    //         }
    //     };
    //     fetchFacebook();
    // }, []);

    //data

    const IG = 13867
    const FB = 44189
    const YT = 3120
    const klaviyo = 368686

    const subscribers = IG + FB + YT + klaviyo
    const members = bcSum
    const users = 554000

    const total = members + subscribers + users;

    const data = [
        ["Category", "Count"],
        [`Members = ${members.toLocaleString()}`, members],
        [`Subscribers = ${subscribers.toLocaleString()}`, subscribers],
        [`Users = ${users.toLocaleString()}`, users]
    ];

    const options = {
        is3D: true,
        colors: ['#c08497', '#da627d', '#a53860'],
        backgroundColor: 'transparent',
        chartArea: { left: 0, top: 20, bottom: 10, width: '100%' },
        legend: {
            position: 'right',
            textStyle: {
                color: 'white',
                fontSize: 15
            }
        },
        pieSliceText: 'display',  // Remove text from pie slices
        slices: {
            0: { offset: 0.1 }, // Optional: offset first slice for emphasis
            1: { offset: 0.1 }, // Optional: offset second slice for emphasis
            2: { offset: 0.1 }, // Optional: offset third slice for emphasis
        },
        pieSliceTextStyle: {
            color: colors.SKcustom[10],
            fontSize: 12
        },
        titleTextStyle: {
            color: 'white',
            fontSize: 10
        }
    };

    //Dropdown
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => {
        setShowDropdown(prevState => !prevState);
    };

    return (
        // 
        <div style={{ marginLeft: '10px' }}>
            <div >
                <div>
                    <img src={theme.palette.mode === 'dark' ? SNLogo : SNLogo2} alt="Image 3" style={{ width: '250px', marginBottom: "-30px", marginLeft: '10px' }} />

                </div>
                <p style={{ color: colors.SKcustom[10], marginLeft: '15x' }} >June 2019 - Oct 2024</p>

            </div>

            <div style={{ width: '500px' }}>
                <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    width={"100%"}
                // height={"300px"}
                />
            </div>

            <div style={{ marginBottom: '20px' }}>
                <SNsubscribers />
            </div>

            {/* REGISTERED MEMBERS */}
            <div style={{ borderTop: '2px solid', borderColor: colors.SKcustom[802], width: '80%', height: '24px', color: colors.SKcustom[802], fontSize: '20px', fontWeight: 800, marginBottom: '15px' }}>
                REGISTERED MEMBERS: <span style={{ color: colors.grey[300], fontWeight: 400 }}>
                    {Number.isFinite(registeredMembers) ? registeredMembers.toLocaleString() : '0'}
                </span>
            </div>
            {/* Big Commerce */}
            <div className="sourceStatL" style={{ color: colors.SKcustom[10], border: 'none', marginBottom: '20px', width: '800px', marginLeft: '5px' }}>
                <div className="rowPlain">
                    <button onClick={toggleDropdown} className="dropdown" style={{ marginLeft: '5px', width: '30px', color: colors.SKcustom[10] }}>
                        {showDropdown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                    {/* Conditionally render  component */}
                    {showDropdown && <UsBc />}
                    <div className="sourceStatHeader">BigCommerce:<span style={{ color: colors.SKcustom[20], fontSize: '16px', fontWeight: 400 }}> &nbsp; {formattedBcSum} members</span></div>
                </div>
            </div>


            {/* SITE USERS */}
            <div style={{ borderTop: '2px solid', borderColor: colors.SKcustom[802], width: '80%', height: '24px', color: colors.SKcustom[802], fontSize: '20px', fontWeight: 800, marginBottom: '15px' }}>
                SITE USERS: <span style={{ color: colors.grey[300], fontWeight: 400 }}> {users.toLocaleString()}  </span>
            </div>

            <SnGA4 />

            {/* <div style={{ marginBottom: '20px' }}>
                <SNcaseStudy />
            </div> */}








        </div >
    )

};

export default ReachSN