import React, { useEffect, useState } from 'react';
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import '../../components/visuals/design/skSpecial.css'


const GnGA4 = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        // 
        <div style={{ marginLeft: '10px' }}>

            <div className="sourceStatL" style={{ color: colors.SKcustom[10], border: 'none', marginBottom: '20px', width: '800px', marginLeft: '5px' }}>
                <div className="sourceStatHeader">GA4 All-Time:<span style={{ color: colors.SKcustom[20], fontSize: '16px', fontWeight: 400 }}> &nbsp; 48K Users &nbsp;| &nbsp;  41K New users</span></div>
            </div>

        </div >
    )

};

export default GnGA4