import { useEffect, useState, useMemo } from "react"
//aesthetics
import { tokens } from "../../theme"
import { useTheme } from '@mui/material';
import HexToRgba from '../../components/visuals/design/hexToRgba';
import '../../components/visuals/design/videoMetrics.css';
import '../../components/visuals/design/sk.css';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AssessmentIcon from '@mui/icons-material/Assessment';

import Logo from "../../img/pantry-logo-green.png"
import FreqMinWatched from "../../components/PantryMetrics/FreqMinWatched"
import PantryOrders from "../../components/PantryMetrics/PantryOrders";

//platform
import PantryPlatform from "../../components/PantryMetrics/PantryPlatform"

//charts
import GoogleAds from "../../components/PantryMetrics/GoogleAds"
import PantryFB from "../../components/PantryMetrics/PantryFB"
import PantryEmails from "../../components/PantryMetrics/PantryEmails"
import PageVisits from "../../components/PantryMetrics/PageVisits"
import PantryIG from "../../components/PantryMetrics/PantryIG"
import PantryYT from "../../components/PantryMetrics/PantryYT";

//clinets
import Saratoga from "../../components/PantryMetrics/Saratoga";
import Camarena from "../../components/PantryMetrics/Camarena";
import CrownMaple from "../../components/PantryMetrics/CrownMaple";

import SaratogaMetrics from "../../components/PantryMetrics/saratogaMetrics";
// import { pink } from "@mui/material/colors";

// import freqMin from "../../components/PantryMetrics/FreqTotal"

const Pantry = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //press link
    const navigate = () => {
        window.location.href = 'https://docs.google.com/spreadsheets/d/14iURe81JuMUMh0kViVpwnvyUUcTGRHfTJTRTmtngevk/edit?usp=sharing';
    };

    //pop-ups
    const [googleAdsVisible, setGoogleAdsVisible] = useState(false);
    const googleAdsModal = () => {
        setGoogleAdsVisible(!googleAdsVisible);
    };

    const [pantryFBVisible, setPantryFBVisible] = useState(false);
    const pantryFBModal = () => {
        setPantryFBVisible(!pantryFBVisible);
    };

    const [emailVisible, setEmailVisible] = useState(false);
    const emailModal = () => {
        setEmailVisible(!emailVisible);
    };

    const [pageVisitsVisible, setPageVisitsVisible] = useState(false);
    const pageVisitsModal = () => {
        setPageVisitsVisible(!pageVisitsVisible);
    };

    const [ytVisible, setYtVisible] = useState(false);
    const ytModal = () => {
        setYtVisible(!ytVisible);
    };

    const [igVisible, setIgVisible] = useState(false);
    const igModal = () => {
        setIgVisible(!igVisible);
    };

    const [saratogaVisible, setSaratogaVisible] = useState(false);
    const saratogaModal = () => {
        setSaratogaVisible(!saratogaVisible);
    };

    //METRIC REQUESTS

    ////META///////////////////////////////
    // FB DB TOTAL
    const [fb, setFb] = useState(0)
    useEffect(() => {
        const fetchFb = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryfb/total`);
            const data = await response.json();

            if (response.ok) {
                setFb(data);
            }
        }
        fetchFb()
    }, [])

    //IG LIVE TOTAL
    const [totalInstagram, setTotalInstagram] = useState(0)
    useEffect(() => {
        const fetchTotal = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/meta/total`);
            const data = await response.json();

            if (response.ok) {
                setTotalInstagram(data)
            }
        }
        fetchTotal()
    }, [])

    /////YOUTUBE/////////////////////////////
    //youTube Saratoga 
    const [ytSaratoga, setYtSaratoga] = useState(0)
    useEffect(() => {
        const fetchTotal = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryyoutube/saratoga`);
            const data = await response.json();

            if (response.ok) {
                setYtSaratoga(Number(data))
            }
        }
        fetchTotal()
    }, [])

    //youTube Series Video
    const [ytViews, setYtViews] = useState(0)
    useEffect(() => {
        const fetchTotal = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryyoutube`);
            const data = await response.json();

            if (response.ok) {
                setYtViews(Number(data))
            }
        }
        fetchTotal()
    }, [])

    const ytTotal = ytViews + ytSaratoga
    const formattedYT = ytTotal.toLocaleString()

    // let formattedYT;
    // if (!isNaN(ytTotal)) {
    //     formattedYT = ytTotal.toLocaleString();
    //     console.log('Formatted YT:', formattedYT);
    // } else {
    //     console.log("Provided value is not a number");
    // }

    ////GOOGLE//////////////////////
    //GoogleAds DATABASE
    const [gaImp, setGaImp] = useState(0)
    useEffect(() => {
        const fetchGaImp = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/googleads/total`);
            const data = await response.json();

            if (response.ok) {
                setGaImp(Number(data));
            }
        }
        fetchGaImp()
    }, [])

    const formattedGaImp = useMemo(() => {
        return gaImp.toLocaleString();
    }, [gaImp]);

    //FREQUENCY//////////////////////
    const [freqMin, setFreqMin] = useState(0)
    useEffect(() => {
        const fetchFreqMin = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/temp/freqtotal`);
            const data = await response.json();

            if (response.ok) {
                setFreqMin(data.sum);
            }
        }
        fetchFreqMin()
    }, [])

    //EMAILS/////////////////////////
    const [email, setEmail] = useState(0)
    useEffect(() => {
        const fetchEmail = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantryemails/total`);
            const data = await response.json();

            if (response.ok) {
                setEmail(data);
            }
        }
        fetchEmail()
    }, [])

    //PAGEVISITS//////////////////////////
    const [pageVisits, setPageVisits] = useState(0)
    useEffect(() => {
        const fetchPageVisits = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrypage/total`);
            const data = await response.json();

            if (response.ok) {
                setPageVisits(data);
            }
        }
        fetchPageVisits()
    }, [])

    /////////////////////////////////
    //OVERVIEW///////////////////////
    const totalMeta = fb + totalInstagram

    //IMPRESSION TOTAL
    const totalImp = gaImp + fb + totalInstagram + email + pageVisits

    //VIEW TOTAL
    const totalViews = totalInstagram + ytTotal + 403689;



    //sales
    //subtotal
    const [subTotal, setSubtotal] = useState(0);
    useEffect(() => {
        const fetchSubTotal = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/subtotal`);
            const data = await response.json();
            if (response.ok) {
                setSubtotal(data.toLocaleString());
            }
        };
        fetchSubTotal();
    }, []);

    //units
    const [totalUnits, setTotalUnits] = useState(0);
    useEffect(() => {
        const fetchTotalUnits = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/units`);
            const data = await response.json();


            if (response.ok) {
                setTotalUnits(data.toLocaleString());
            }
        };
        fetchTotalUnits();
    }, []);

    //top sellers
    const [topSellers, setTopSellers] = useState([]);
    useEffect(() => {
        const fetchTopSellers = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/top`);
            const data = await response.json();

            if (response.ok) {
                setTopSellers(data[0].topProducts);
            }
        };
        fetchTopSellers();
    }, []);

    //top states
    const [topStates, setTopStates] = useState([]);
    useEffect(() => {
        const fetchTopStates = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/states`);
            const data = await response.json();

            if (response.ok) {
                setTopStates(data);
            }
        };
        fetchTopStates();
    }, []);

    //camarena
    const [totalCamarenaUnits, setTotalCamarenaUnits] = useState(0);
    useEffect(() => {
        const fetchTotalCamarenaUnits = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/camarenaunits`);
            const data = await response.json();


            if (response.ok) {
                setTotalCamarenaUnits(data.toLocaleString());
            }
        };
        fetchTotalCamarenaUnits();
    }, []);
    const [camarenaTotal, setCamarenaTotal] = useState(0);
    useEffect(() => {
        const fetchCamarenaTotal = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/camarenarev`);
            const data = await response.json();
            if (response.ok) {
                setCamarenaTotal(data.toLocaleString());
            }
        };
        fetchCamarenaTotal();
    }, []);

    //saratoga
    const [totalSaratogaUnits, setTotalSaratogaUnits] = useState(0);
    useEffect(() => {
        const fetchTotalSaratogaUnits = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/saratogaunits`);
            const data = await response.json();


            if (response.ok) {
                setTotalSaratogaUnits(data.toLocaleString());
            }
        };
        fetchTotalSaratogaUnits();
    }, []);
    const [saratogaTotal, setSaratogaTotal] = useState(0);
    useEffect(() => {
        const fetchSaratogaTotal = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/saratogarev`);
            const data = await response.json();
            if (response.ok) {
                setSaratogaTotal(data.toLocaleString());
            }
        };
        fetchSaratogaTotal();
    }, []);

    //Crown Maple
    const [totalCrownUnits, setTotalCrownUnits] = useState(0);
    useEffect(() => {
        const fetchTotalCrownUnits = async () => {

            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/crownunits`);
            const data = await response.json();


            if (response.ok) {
                setTotalCrownUnits(data.toLocaleString());
            }
        };
        fetchTotalCrownUnits();
    }, []);
    const [crownTotal, setCrownTotal] = useState(0);
    useEffect(() => {
        const fetchCrownTotal = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/pantrysales/crownrev`);
            const data = await response.json();
            if (response.ok) {
                setCrownTotal(data.toLocaleString());
            }
        };
        fetchCrownTotal();
    }, []);


    return (
        <div style={{ paddingLeft: "10px" }}>

            {/* logo */}
            <img src={Logo} alt="The Pantry" style={{ height: "50px" }} />


            {/* bar */}
            <div style={{ backgroundColor: "#ab966d", height: "0.5em", marginTop: "20px", marginBottom: "20px" }}></div>

            <div className="metricColumn" >
                {/* <div style={{ backgroundColor: "#FF0090", color: "#000", fontSize: "14px", width: "65%", paddingLeft: "9px" }}>Warning: Total views may be lower than actual due to a problem with Instagram's automated reporting. If this isn't resolved by 7/9, SK will update the backed to manual reporting. Thx  </div> */}

                <div className="metricRow">



                    {/* Platform*/}
                    <div className="metricCard" style={{ maxWidth: "600px", backgroundColor: HexToRgba(colors.SKcustom[34], 0.4), paddingBottom: "10px", paddingTop: "5px", }}>
                        <h1 style={{ color: colors.SKcustom[10], fontWeight: '400' }}>SN App - Season 1</h1>
                        <div>
                            <PantryPlatform />
                        </div>
                    </div>

                    <div className="metricColumn" >

                        {/* DIGITAL*/}
                        <div className="tallMetricCard" style={{ backgroundColor: HexToRgba(colors.SKcustom[36], 0.5), paddingTop: "5px" }}>

                            <h1 style={{ color: colors.SKcustom[10], fontWeight: '400' }}>Digital Campaign • Season 1 </h1>

                            {/* top level */}
                            <div className="row" style={{ color: colors.SKcustom[10] }}>

                                <div className="miniMetricCard">
                                    <div className="column"></div>
                                    <p className="miniStat">{totalImp.toLocaleString()}</p>
                                    <p>IMPS</p>
                                </div>
                                <div className="miniMetricCard">
                                    <div className="column"></div>
                                    <p className="miniStat">{totalViews.toLocaleString()}</p>
                                    <p>VIEWS</p>
                                </div>
                            </div>

                            <hr style={{ border: "1px solid ", margin: "10px 0px 25px", borderColor: colors.SKcustom[11] }} />

                            {/* IMP BREAKDOWN*/}
                            <div style={{ color: colors.SKcustom[10], marginTop: "-15px", marginBottom: "0px" }}>BREAKDOWN OF IMPRESSIONS</div>

                            <div style={{ marginLeft: "10px", marginBottom: "20px" }}>

                                {/* Google Ads*/}
                                <div onClick={googleAdsModal} class="metricRow">
                                    <div className="vHeader" style={{ display: 'flex', alignItems: 'center', color: colors.SKcustom[10], marginBottom: '5px' }}>
                                        <span className="number" style={{ color: colors.SKcustom[14], marginRight: '10px' }}>
                                            {formattedGaImp}
                                        </span>
                                        GoogleAds
                                        <ExpandMoreIcon className="expand" style={{ color: colors.SKcustom[10], marginLeft: '5px' }} />
                                    </div>

                                </div>

                                {/* Meta */}
                                <div onClick={pantryFBModal} class="metricRow">
                                    <div className="vHeader" style={{ display: 'flex', alignItems: 'center', color: colors.SKcustom[10], marginBottom: '5px' }}>
                                        <span className="number" style={{ color: colors.SKcustom[14], marginRight: '10px' }}>
                                            {totalMeta.toLocaleString()}</span> Meta (FB + IG)
                                        <ExpandMoreIcon className="expand" style={{ color: colors.SKcustom[10], marginLeft: '5px' }} />
                                    </div>
                                </div>

                                {/* Email */}
                                <div onClick={emailModal} class="metricRow">
                                    <div className="vHeader" style={{ display: 'flex', alignItems: 'center', color: colors.SKcustom[10], marginBottom: '5px' }}>
                                        <span className="number" style={{ color: colors.SKcustom[14], marginRight: '10px' }}>
                                            {email.toLocaleString()}
                                        </span>
                                        Email (opens)
                                        <ExpandMoreIcon className="expand" style={{ color: colors.SKcustom[10], marginLeft: '5px' }} />
                                    </div>
                                </div>

                                {/* Page Visits */}
                                <div onClick={pageVisitsModal} class="metricRow">
                                    <div className="vHeader" style={{ display: 'flex', alignItems: 'center', color: colors.SKcustom[10], marginBottom: '5px' }}>
                                        <span className="number" style={{ color: colors.SKcustom[14], marginRight: '10px' }}>
                                            {pageVisits.toLocaleString()}
                                        </span>
                                        .com visits
                                        <ExpandMoreIcon className="expand" style={{ color: colors.SKcustom[10], marginLeft: '5px' }} />
                                    </div>
                                </div>

                            </div>

                            {/* VIEWS */}

                            <div style={{ color: colors.SKcustom[10], marginTop: "-10px", marginBottom: "5px" }}>BREAKDOWN OF VIEWS</div>

                            <div style={{ marginLeft: "10px", marginBottom: "20px" }}>
                                {/* PLATFORM */}
                                <div class="metricRow">
                                    <div class="metricRow">
                                        <div className="vHeader" style={{ display: 'flex', alignItems: 'center', color: colors.SKcustom[10], marginBottom: '5px' }}>
                                            <span className="number" style={{ color: colors.SKcustom[14], marginRight: '10px' }}>
                                                403,689
                                            </span>
                                            SN Platform  </div>
                                    </div></div>

                                {/* YOUTUBE */}
                                <div onClick={ytModal} class="metricRow">
                                    <div class="metricRow">
                                        <div className="vHeader" style={{ display: 'flex', alignItems: 'center', color: colors.SKcustom[10], marginBottom: '5px' }}>
                                            <span className="number" style={{ color: colors.SKcustom[14], marginRight: '10px' }}>
                                                {formattedYT}
                                            </span>
                                            Youtube  <ExpandMoreIcon className="expand" style={{ color: colors.SKcustom[10], marginLeft: '5px' }} /></div>
                                    </div></div>

                                {/* INSTAGRAM */}
                                <div onClick={igModal} class="metricRow">
                                    <div className="vHeader" style={{ display: 'flex', alignItems: 'center', color: colors.SKcustom[10], marginBottom: '5px' }}>
                                        <span className="number" style={{ color: colors.SKcustom[14], marginRight: '10px' }}>
                                            {totalInstagram.toLocaleString()}
                                        </span>
                                        Instagram
                                        <ExpandMoreIcon className="expand" style={{ color: colors.SKcustom[10], marginLeft: '5px' }} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="metricColumn" >

                    <div className="metricRow">

                        {/* top products */}
                        <div className="metricCard" style={{ height: "300px", maxWidth: "400px", backgroundColor: HexToRgba(colors.SKcustom[35], 0.35) }}>
                            <div className="column" style={{ width: "300px" }}>
                                <p className="vHeader" style={{ marginBottom: "-10px", color: colors.SKcustom[14] }}>Top Sellers • Season 1:</p>
                                <div style={{ color: colors.SKcustom[10] }}>
                                    <ol style={{ paddingLeft: 10 }}>
                                        {topSellers.map((item, index) => (
                                            <li key={index}>

                                                <span style={{ fontSize: '0.9em', fontWeight: 600, color: colors.grey[22], marginRight: "" }}>
                                                    {item.product}
                                                </span> |

                                                <span style={{ fontSize: '0.8em', fontWeight: 400, color: colors.SKcustom[33], marginRight: "" }}>
                                                    {` ${item.totalUnits}`} sold
                                                </span> |

                                                <span style={{ fontSize: '0.8em' }}>
                                                    {` ${item.totalSales}`}</span>

                                            </li>
                                        ))}
                                    </ol>
                                </div>
                            </div>
                        </div>

                        {/* SALES*/}
                        <div className="metricColumn">
                            {/* subtotal */}
                            <div className="metricCard" style={{ maxWidth: "200px", backgroundColor: HexToRgba(colors.SKcustom[35], 0.35) }}>
                                <div className="metricColumn">

                                    {/* total units sold */}
                                    <div className="statRow" style={{ marginBottom: "-10px" }} >
                                        <p className="vHeader" style={{ color: colors.SKcustom[14] }}>Total Units Sold:</p>
                                        <p style={{ color: colors.SKcustom[10], paddingLeft: "10px" }}> {totalUnits} </p>
                                    </div>

                                    {/* total rev*/}
                                    <div className="statRow" >
                                        <p className="vHeader" style={{ color: colors.SKcustom[14] }}>Total Sales:</p>
                                        <p style={{ color: colors.SKcustom[10], paddingLeft: "10px" }}> ${subTotal} </p>
                                    </div>

                                    {/* top states */}
                                    <div className="column">
                                        <p className="vHeader" style={{ marginTop: "0px", marginBottom: "-10px", color: colors.SKcustom[14] }}>Top States:</p>
                                        <ol style={{ color: colors.SKcustom[10], paddingLeft: 10, fontSize: "11px" }}>
                                            {topStates.map((state, index) => (
                                                <li key={index}>{state._id} </li>
                                            ))}
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* CTV*/}
                        <div className="metricCard" style={{ maxWidth: "300px", backgroundColor: HexToRgba(colors.SKcustom[34], 0.4) }}>
                            <h1 style={{ color: colors.SKcustom[10] }}>CTV</h1>
                            <div style={{ marginTop: "-20px", textAlign: "center" }}>
                                <h2 className="vHeader" style={{ color: colors.SKcustom[10] }}><span className="number" style={{ color: colors.SKcustom[14] }} > {freqMin.toLocaleString()}</span> MINUTES WATCHED</h2>
                            </div>
                            <div>
                                <FreqMinWatched />
                            </div>
                        </div>
                    </div>

                    {/* CLIENTS */}
                    <div className="rowPlain">
                        {/* Saratoga */}
                        <div onClick={saratogaModal} className="responsiveCard" style={{ backgroundColor: colors.SKcustom[32] }}>
                            <div className="row">
                                <h1 style={{ color: colors.SKcustom[10], paddingLeft: '5px', marginRight: '-30px' }}>Saratoga</h1><AssessmentIcon style={{ color: "#fff" }} />
                            </div>

                            <div className="row" style={{ backgroundColor: '#ab966d', margin: '0px', padding: '0px', lineHeight: '8px' }}>
                                <p style={{ color: colors.SKcustom[10], paddingLeft: '5px' }}>Units sold: {totalSaratogaUnits}</p>
                                <p style={{ color: colors.SKcustom[10] }}>${saratogaTotal}</p>
                            </div>
                            <Saratoga />
                        </div>

                        {/* Camarena */}
                        <div className="responsiveCard" style={{ backgroundColor: HexToRgba(colors.SKcustom[36], 0.5) }}>

                            <h1 style={{ color: colors.SKcustom[10], paddingLeft: '5px' }}>Camarena</h1>
                            <div className="row" style={{ backgroundColor: '#ab966d', margin: '0px', padding: '0px', lineHeight: '8px' }}>
                                <p style={{ color: colors.SKcustom[10], paddingLeft: '5px' }}>Units sold: {totalCamarenaUnits}</p>
                                <p style={{ color: colors.SKcustom[10] }}>Revenue: ${camarenaTotal}</p>
                            </div>
                            <Camarena />
                        </div>
                    </div>
                    <div className="rowPlain">

                        {/* CrownMaple */}
                        <div className="responsiveCard" style={{ backgroundColor: HexToRgba(colors.SKcustom[35], 0.35) }}>
                            <h1 style={{ color: colors.SKcustom[10], paddingLeft: '5px' }}>CrownMaple</h1>
                            <div className="row" style={{ backgroundColor: '#ab966d', margin: '0px', padding: '0px', lineHeight: '8px' }}>
                                <p style={{ color: colors.SKcustom[10], paddingLeft: '5px' }}>Units sold: {totalCrownUnits}</p>
                                <p style={{ color: colors.SKcustom[10] }}>Revenue: ${crownTotal}</p>
                            </div>
                            <CrownMaple />
                        </div>
                    </div>



                    {/* PRESS*/}
                    <button
                        onClick={navigate}
                        style={{
                            maxWidth: "75px",
                            backgroundColor: HexToRgba(colors.SKcustom[40], 0.8),
                            border: "none",
                            margin: "2px 0px 2px 0px",
                            padding: "7px 2px",
                            cursor: "pointer",
                            borderRadius: "5px",
                            color: "black",
                            fontSize: "10px"
                        }}>Press Archive
                    </button>

                    {/* POPUPS */}
                    {/* googleAds popup */}
                    {googleAdsVisible && (
                        <div
                            style={{
                                position: "fixed",
                                width: "400px",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: HexToRgba(colors.SKcustom[35], 0.95),
                                padding: "20px",
                                zIndex: 1000, // Ensure modal is above other content
                                textAlign: "center",
                                border: "0.5px solid rgba(255, 255, 255, 0.5)",
                                borderRadius: "5px"
                            }}
                        >

                            <button
                                onClick={() => setGoogleAdsVisible(false)}
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                    cursor: "pointer",
                                    border: "none",
                                    background: "none",
                                    color: "white",
                                    fontSize: "24px"
                                }}
                                aria-label="Close"
                            >
                                &times; {/* Stylish 'X' character used as a close button */}
                            </button>

                            <h2 className="vHeader" style={{ color: colors.SKcustom[10] }}>
                                Google Ad Campaign Impressions
                            </h2>
                            <div><GoogleAds /></div>
                        </div>
                    )}

                    {/* pageVisits */}
                    {pageVisitsVisible && (
                        <div
                            style={{
                                position: "fixed",
                                width: "400px",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: HexToRgba(colors.SKcustom[35], 0.95),
                                padding: "10px",
                                zIndex: 1000, // Ensure modal is above other content
                                textAlign: "center",
                                border: "0.5px solid rgba(255, 255, 255, 0.5)",
                                borderRadius: "5px"
                            }}
                        >

                            <button
                                onClick={() => setPageVisitsVisible(false)}
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                    cursor: "pointer",
                                    border: "none",
                                    background: "none",
                                    color: "white",
                                    fontSize: "24px"
                                }}
                                aria-label="Close"
                            >
                                &times; {/* Stylish 'X' character used as a close button */}
                            </button>

                            <div style={{ color: colors.SKcustom[10] }}>Page Visits<PageVisits /></div>

                        </div>
                    )}

                    {/* FB */}
                    {pantryFBVisible && (
                        <div
                            style={{
                                position: "fixed",
                                width: "400px",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: HexToRgba(colors.SKcustom[35], 0.95),
                                padding: "10px",
                                zIndex: 1000, // Ensure modal is above other content
                                textAlign: "center",
                                border: "0.5px solid rgba(255, 255, 255, 0.5)",
                                borderRadius: "5px"
                            }}
                        >

                            <button
                                onClick={() => setPantryFBVisible(false)}
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                    cursor: "pointer",
                                    border: "none",
                                    background: "none",
                                    color: "white",
                                    fontSize: "24px"
                                }}
                                aria-label="Close"
                            >
                                &times; {/* Stylish 'X' character used as a close button */}
                            </button>

                            <div style={{ color: colors.SKcustom[10] }}>Facebook ads (combine with IG for total Meta)<PantryFB /></div>

                        </div>
                    )}

                    {/* email popup */}
                    {emailVisible && (
                        <div
                            style={{
                                position: "fixed",
                                width: "400px",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: HexToRgba(colors.SKcustom[35], 0.95),
                                padding: "10px",
                                zIndex: 1000, // Ensure modal is above other content
                                textAlign: "center",
                                border: "0.5px solid rgba(255, 255, 255, 0.5)",
                                borderRadius: "5px"
                            }}
                        >

                            <button
                                onClick={() => setEmailVisible(false)}
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                    cursor: "pointer",
                                    border: "none",
                                    background: "none",
                                    color: "white",
                                    fontSize: "24px"
                                }}
                                aria-label="Close"
                            >
                                &times; {/* Stylish 'X' character used as a close button */}
                            </button>

                            <h2 className="vHeader" style={{ color: colors.SKcustom[10] }}>
                                EMAIL OPENS
                            </h2>
                            <div><PantryEmails /></div>
                        </div>
                    )}


                    {/* YT pop up */}
                    {ytVisible && (
                        <div
                            style={{
                                position: "fixed",
                                width: "400px",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: HexToRgba(colors.SKcustom[35], 0.95),
                                padding: "10px",
                                zIndex: 1000, // Ensure modal is above other content
                                textAlign: "center",
                                border: "0.5px solid rgba(255, 255, 255, 0.5)",
                                borderRadius: "5px"
                            }}
                        >

                            <button
                                onClick={() => setYtVisible(false)}
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                    cursor: "pointer",
                                    border: "none",
                                    background: "none",
                                    color: "white",
                                    fontSize: "24px"
                                }}
                                aria-label="Close"
                            >
                                &times; {/* Stylish 'X' character used as a close button */}
                            </button>

                            <h2 className="vHeader" style={{ color: colors.SKcustom[10] }}>
                                <span className="number" style={{ color: colors.SKcustom[14] }}>
                                    {ytTotal.toLocaleString()}
                                </span> YouTube Views (all time)
                            </h2>
                            <div><PantryYT /></div>
                        </div>
                    )}

                    {/* IG pop up */}
                    {igVisible && (
                        <div
                            style={{
                                position: "fixed",
                                width: "400px",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: HexToRgba(colors.SKcustom[35], 0.95),
                                padding: "10px",
                                zIndex: 1000, // Ensure modal is above other content
                                textAlign: "center",
                                border: "0.5px solid rgba(255, 255, 255, 0.5)",
                                borderRadius: "5px"
                            }}
                        >

                            <button
                                onClick={() => setIgVisible(false)}
                                style={{
                                    position: "absolute",
                                    right: "10px",
                                    top: "10px",
                                    cursor: "pointer",
                                    border: "none",
                                    background: "none",
                                    color: "white",
                                    fontSize: "24px"
                                }}
                                aria-label="Close"
                            >
                                &times; {/* Stylish 'X' character used as a close button */}
                            </button>

                            <h2 className="vHeader" style={{ color: colors.SKcustom[10] }}>
                                <span className="number" style={{ color: colors.SKcustom[14] }}>
                                    {totalInstagram.toLocaleString()}
                                </span> INSTAGRAM VIEWS
                            </h2>
                            <div><PantryIG /></div>
                        </div>
                    )}


                    <div>
                        {/* Saratoga pop up */}
                        {saratogaVisible && (
                            <div
                                style={{
                                    position: "fixed",
                                    width: "900px",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)",
                                    backgroundColor: HexToRgba(colors.SKcustom[35], 0.95),
                                    padding: "10px",
                                    zIndex: 1000, // Ensure modal is above other content
                                    textAlign: "center",
                                    border: "0.5px solid rgba(255, 255, 255, 0.5)",
                                    borderRadius: "5px"
                                }}
                            >

                                <button
                                    onClick={() => setSaratogaVisible(false)}
                                    style={{
                                        position: "absolute",
                                        right: "10px",
                                        top: "10px",
                                        cursor: "pointer",
                                        border: "none",
                                        background: "none",
                                        color: "white",
                                        fontSize: "24px"
                                    }}
                                    aria-label="Close"
                                >
                                    &times; {/* Stylish 'X' character used as a close button */}
                                </button>

                                <div><SaratogaMetrics /></div>
                            </div>
                        )}

                    </div>


                </div>
            </div >

            {/* orders */}
            < div > < PantryOrders /></div >
        </div >
    )
}
export default Pantry



