import React, { useEffect, useState } from 'react';
import { Chart } from "react-google-charts";

import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import '../components/visuals/design/skSpecial.css'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import GNLogo_white from "../img/GN.png"
import GNLogo_blue from "../img/GN_blue.png"

import GNsubscribers from "../components/GN/GNsubscribers";
import GnBc from "../components/GN/GnBc"
import GnGA4 from "../components/GN/GnGA4"

const GNmembersReach = () => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    //Klaviyo
    const [klaviyo, setKlaviyo] = useState();
    useEffect(() => {
        const fetchKlaviyo = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/golfklaviyo/active`);
                const data = await response.json();

                if (response.ok) {
                    setKlaviyo(data);
                } else {
                    console.error('Response not ok:', data);
                }
            } catch (error) {
                console.error('Error fetching Klaviyo data:', error);
            }
        };
        fetchKlaviyo();
    }, []);

    //Big Commerce
    const [bcSum, setSum] = useState(0);
    useEffect(() => {
        const fetchSum = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/gnup/total`);

            const data = await response.json()

            if (response.ok) {
                setSum(data)
            }
        };
        fetchSum();

    }, []);
    const formattedBcSum = bcSum.toLocaleString()

    // Data
    const registeredMembers = bcSum

    //Dropdown
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => {
        setShowDropdown(prevState => !prevState);
    };

    const IG = 6750
    const FB = 7
    const YT = 15

    //data
    const subscribers = IG + FB + YT + klaviyo
    const members = bcSum
    const users = 48292

    const total = members + subscribers + users;

    const data = [
        ["Category", "Count"],
        [`Members = ${members}`, members],
        [`Subscribers = ${subscribers.toLocaleString()}`, subscribers],
        [`Users = ${users.toLocaleString()}`, users]
    ];

    const options = {
        is3D: true,
        colors: ['#c08497', '#da627d', '#a53860'],
        backgroundColor: 'transparent',
        chartArea: { left: 0, top: 20, bottom: 10, width: '100%', height: '80%' },
        legend: {
            position: 'right',  // Position of the legend
            textStyle: {
                color: 'white',  // Set the legend text color to white
                fontSize: 15
            }
        },
        pieSliceText: 'display',  // Remove text from pie slices
        slices: {
            0: { offset: 0.1 }, // Optional: offset first slice for emphasis
            1: { offset: 0.1 }, // Optional: offset second slice for emphasis
            2: { offset: 0.1 }, // Optional: offset third slice for emphasis
        },
        pieSliceTextStyle: {
            color: colors.SKcustom[10],
            fontSize: 12
        },
        titleTextStyle: {
            color: 'white',
            fontSize: 10
        }
    };

    return (
        // 
        <div style={{ marginLeft: '10px' }}>

            <div >
                <div>
                    <img src={theme.palette.mode === 'dark' ? GNLogo_white : GNLogo_blue} alt="Image 3" style={{ width: '250px', marginBottom: "-30px", marginLeft: '10px' }} />
                </div>
            </div>

            <div style={{ width: '500px' }}>
                <Chart
                    chartType="PieChart"
                    data={data}
                    options={options}
                    // width={"100%"}
                    height={"200px"}
                />
            </div>

            <GNsubscribers />

            {/* REGISTERED MEMBERS */}
            <div style={{ borderTop: '2px solid', borderColor: colors.SKcustom[802], width: '80%', height: '24px', color: colors.SKcustom[802], fontSize: '20px', fontWeight: 800, marginBottom: '15px' }}>
                REGISTERED MEMBERS: <span style={{ color: colors.grey[300], fontWeight: 400 }}>
                    {Number.isFinite(registeredMembers) ? registeredMembers.toLocaleString() : '0'}
                </span>
            </div>

            {/* Big Commerce */}
            <div className="sourceStatL" style={{ color: colors.SKcustom[10], border: 'none', marginBottom: '20px', width: '800px', marginLeft: '5px' }}>
                <div className="rowPlain">
                    <button onClick={toggleDropdown} className="dropdown" style={{ marginLeft: '5px', width: '30px', color: colors.SKcustom[10] }}>
                        {showDropdown ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </button>
                    {/* Conditionally render GnBc component */}
                    {showDropdown && <GnBc />}
                    <div className="sourceStatHeader">BigCommerce:<span style={{ color: colors.SKcustom[20], fontSize: '16px', fontWeight: 400 }}> &nbsp; {formattedBcSum} members</span></div>
                </div>
            </div>

            {/* SITE USERS */}
            <div style={{ borderTop: '2px solid', borderColor: colors.SKcustom[802], width: '80%', height: '24px', color: colors.SKcustom[802], fontSize: '20px', fontWeight: 800, marginBottom: '15px' }}>
                SITE USERS: <span style={{ color: colors.grey[300], fontWeight: 400 }}> 48,292  </span>
            </div>

            <GnGA4 />

        </div >
    )

};

export default GNmembersReach