import { useEffect, useState, useRef } from 'react';
import { tokens } from "../theme";
import { useTheme } from '@mui/material';
import hexToRgba from '../components/visuals/design/hexToRgba';
import '../components/visuals/design/treasure.css';


const Treasure5 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ color: hexToRgba('#66FF00', 0.9), fontSize: '20px', display: 'flex', flexDirection: 'column', marginBottom: '10px' }}>
                <div>Test.</div>

            </div>


        </div>
    );
};

export default Treasure5;
