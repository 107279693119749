import React, { useEffect, useState, useRef } from "react";
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button
} from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import HexToRgba from '../../components/visuals/design/hexToRgba';
import "../../components/visuals/design/skSpecial.css";

const Negroni24 = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const [data, setData] = useState([]);
    const [groupTotals, setGroupTotals] = useState({});
    const [campaignTotals, setCampaignTotals] = useState({});
    const scrollContainerRef = useRef(null);
    const topScrollContainerRef = useRef(null);
    const [tableWidth, setTableWidth] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/negroni`);
            const data = await response.json();

            if (response.ok) {
                setData(data);
            }
        };

        const fetchGroupTotals = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/negroni/totals`);
            const totals = await response.json();

            if (response.ok) {
                setGroupTotals(totals);
            }
        };

        const fetchCampaignTotals = async () => {
            const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/negroni/totals`);
            const totals = await response.json();

            if (response.ok) {
                setCampaignTotals(totals.totalSum)
            }

        };

        fetchData();
        fetchGroupTotals();
        fetchCampaignTotals()

        if (scrollContainerRef.current) {
            setTableWidth(scrollContainerRef.current.scrollWidth);
        }
    }, []);

    const handleScroll = (scrollContainer, syncedScrollContainer) => {
        if (syncedScrollContainer.current && scrollContainer.current) {
            syncedScrollContainer.current.scrollLeft = scrollContainer.current.scrollLeft;
        }
    };


    // Function to convert data into CSV format and trigger download
    const downloadCSV = () => {
        const csvHeader = [
            "Prisma Code,Tactic,Flight Start,Flight End,Buy Model,Planned Impressions,Planned Dollars,Total Imps,Total Clicks,Total Views,Total Sales,Sept Imps,Sept Clicks,Sept Views,Sept Sales,Oct Imps,Oct Clicks,Oct Views,Oct Sales"
        ];

        const sanitizeValue = (value) => (value === "*" ? "" : value);

        const csvRows = Object.keys(groupedData).flatMap((group) => {
            const groupRows = groupedData[group];
            const groupTotalImps = groupTotals[group]?.totalImps || 0;
            const groupTotalClicks = groupTotals[group]?.totalClicks || 0;
            const groupTotalViews = groupTotals[group]?.totalViews || 0;
            const groupTotalSales = groupTotals[group]?.totalSales || 0;

            return groupRows.map((row, rowIndex) => {
                // Totals and planned values only for the first row of each group
                const totalImp = rowIndex === 0 ? groupTotalImps : '';
                const totalClicks = rowIndex === 0 ? groupTotalClicks : '';
                const totalViews = rowIndex === 0 ? groupTotalViews : '';
                const totalSales = rowIndex === 0 ? groupTotalSales : '';

                const septImps = sanitizeValue(row.septImps || '');
                const septClicks = sanitizeValue(row.septClicks || '');
                const septViews = sanitizeValue(row.septViews || '');
                const septSales = sanitizeValue(row.septSales || '');
                const octImps = sanitizeValue(row.octImps || '');
                const octClicks = sanitizeValue(row.octClicks || '');
                const octViews = sanitizeValue(row.octViews || '');
                const octSales = sanitizeValue(row.octSales || '');

                return [
                    `"${row.prismaCode || ''}"`,
                    `"${row.tactic || ''}"`,
                    `"${row.flightStart || ''}"`,
                    `"${row.flightEnd || ''}"`,
                    `"${row.buyModel || ''}"`,
                    `"${row.plannedImp || ''}"`,
                    `"${row.plannedDollars || ''}"`,
                    `"${totalImp || ''}"`,
                    `"${totalClicks || ''}"`,
                    `"${totalViews || ''}"`,
                    `"${totalSales || ''}"`,
                    `"${septImps}"`,
                    `"${septClicks}"`,
                    `"${septViews}"`,
                    `"${septSales}"`,
                    `"${octImps}"`,
                    `"${octClicks}"`,
                    `"${octViews}"`,
                    `"${octSales}"`
                ].join(",");
            });
        });

        const csvContent = [csvHeader.join(","), ...csvRows].join("\n");
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "NegroniCampaign2024.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const plannedImps = {
        1: '240,000',
        2: '60,000',
        3: '500,000',
        4: '125,000',
    };

    const plannedDollars = {
        1: '0',
        2: '0',
        3: '$80,000',
        4: '$20,000',
    };

    // Helper to group data by group field
    const groupedData = data.reduce((acc, row) => {
        acc[row.group] = acc[row.group] || [];
        acc[row.group].push(row);
        return acc;
    }, {});

    return (
        <div style={{ color: colors.SKcustom[10], marginLeft: "10px" }}>
            <p><span style={{ fontWeight: 900 }}>Campari:</span> Negroni Week 2024 </p>

            <div className="tally31" style={{ color: colors.SKcustom[10] }} >

                {/* IMP */}
                < div className='tally32' style={{ borderColor: colors.SKcustom[10] }}>

                    <div style={{ margin: '0px', fontSize: '1.5em', fontWeight: '600', color: colors.SKcustom[17] }}>
                        {campaignTotals.totalImps?.toLocaleString() || 0}
                    </div>
                    <div style={{ fontSize: '1.8em', fontWeight: 900, textTransform: 'uppercase', marginTop: '-10px' }}>imps</div>
                </div>

                {/* VIEWS */}
                < div className='tally32' style={{ borderColor: colors.SKcustom[10] }}>

                    <div style={{ margin: '0px', fontSize: '1.5em', fontWeight: '600', color: colors.SKcustom[17] }}>
                        {campaignTotals.totalViews?.toLocaleString() || 0}
                    </div>
                    <div style={{ fontSize: '1.8em', fontWeight: 900, textTransform: 'uppercase', marginTop: '-10px' }}>views</div>
                </div>

                {/* REACH */}
                < div className='tally32' style={{ borderColor: colors.SKcustom[10] }}>

                    <div style={{ margin: '0px', fontSize: '1.5em', fontWeight: '600', color: colors.SKcustom[17] }}>
                        {campaignTotals.totalClicks?.toLocaleString() || 0}
                    </div>
                    <div style={{ fontSize: '1.8em', fontWeight: 900, textTransform: 'uppercase', marginTop: '-10px' }}>clicks</div>
                </div>
                {/* REACH */}
                < div className='tally32' style={{ borderColor: colors.SKcustom[10] }}>

                    <div style={{ margin: '0px', fontSize: '1.5em', fontWeight: '600', color: colors.SKcustom[17] }}>
                        0
                    </div>
                    <div style={{ fontSize: '1.8em', fontWeight: 900, textTransform: 'uppercase', marginTop: '-10px' }}>sales</div>
                </div>
            </div>


            <Button variant="contained" color="primary" onClick={downloadCSV} style={{ border: `1px solid ${colors.SKcustom[17]}`, width: '150px', fontSize: '10px', padding: '2px', marginBottom: '5px' }}>
                Download Agency Report
            </Button>

            {/* Flexbox to ensure the top scroll container is placed above the table */}
            <div style={{ display: 'flex', flexDirection: 'column', maxWidth: "1200px" }}>
                {/* Top scrollbar div */}
                <div ref={topScrollContainerRef} style={{ overflowX: "auto", marginBottom: "5px", }}
                    onScroll={() => handleScroll(topScrollContainerRef, scrollContainerRef)}>
                    {/* Dummy content to create a scrollbar with the same width as the table */}
                    <div style={{ width: tableWidth, height: '1px' }} />
                </div>
                {/* Table container with bottom scrollbar */}
                <TableContainer ref={scrollContainerRef} component={Paper} style={{ overflowX: "auto", }} onScroll={() => handleScroll(scrollContainerRef, topScrollContainerRef)}>

                    <Table>
                        <TableHead style={{ backgroundColor: '#2B4593' }}>
                            <TableRow>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Prisma Code</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Tactic</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Flight Start</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Flight End</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Buy Model</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Planned Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Planned Dollars</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Clicks</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Views</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Total Sales</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Clicks</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Views</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Sept Sales</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Imps</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Clicks</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Views</TableCell>
                                <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>Oct Sales</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {Object.keys(groupedData).map((group) => {
                                const groupRows = groupedData[group];
                                const groupTotalImps = groupTotals[group]?.totalImps || 0;
                                const groupTotalClicks = groupTotals[group]?.totalClicks || 0;
                                const groupTotalViews = groupTotals[group]?.totalViews || 0;
                                const groupTotalSales = groupTotals[group]?.totalSales || 0;

                                const groupPlannedImps = plannedImps[group];
                                const groupPlannedDollars = plannedDollars[group];

                                return groupRows.map((row, rowIndex) => {


                                    return (
                                        <TableRow
                                            key={rowIndex}
                                            style={{
                                                backgroundColor: row.group % 2 === 0 ? HexToRgba(colors.SKcustom[31], 0.3) : undefined,
                                            }}>

                                            <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.prismaCode}</TableCell>
                                            <TableCell style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px' }}>{row.tactic}</TableCell>
                                            <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.flightStart}</TableCell>
                                            <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.flightEnd}</TableCell>
                                            <TableCell style={{ fontSize: '10px', padding: '4px' }}>{row.buyModel}</TableCell>

                                            {/* Planned */}
                                            {rowIndex === 0 && (
                                                <TableCell rowSpan={groupRows.length} style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}>
                                                    {groupPlannedImps}
                                                </TableCell>
                                            )}
                                            {rowIndex === 0 && (
                                                <TableCell
                                                    rowSpan={groupRows.length}
                                                    style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}
                                                >
                                                    {groupPlannedDollars}
                                                </TableCell>
                                            )}

                                            {/* Totals */}
                                            {rowIndex === 0 && (
                                                <>
                                                    <TableCell rowSpan={groupRows.length} style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}>
                                                        {groupTotalImps.toLocaleString()}
                                                    </TableCell>

                                                    <TableCell rowSpan={groupRows.length} style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}>
                                                        {groupTotalClicks.toLocaleString()}
                                                    </TableCell>

                                                    <TableCell rowSpan={groupRows.length} style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}>
                                                        {groupTotalViews.toLocaleString()}
                                                    </TableCell>

                                                    <TableCell rowSpan={groupRows.length} style={{ fontSize: '10px', whiteSpace: 'nowrap', overflow: 'hidden', padding: '4px', textAlign: 'center' }}>
                                                        {groupTotalSales.toLocaleString()}
                                                    </TableCell>
                                                </>
                                            )}

                                            {/* Monthly Data */}
                                            <TableCell style={{ padding: '4px' }}>{row.septImps.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.septClicks.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.septViews.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.septSales}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.octImps.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.octClicks.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.octViews.toLocaleString()}</TableCell>
                                            <TableCell style={{ padding: '4px' }}>{row.octSales}</TableCell>
                                        </TableRow>
                                    );
                                });
                            })}
                        </TableBody>


                    </Table>
                </TableContainer>
            </div>
        </div>
    );
};

export default Negroni24;
