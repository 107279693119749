import React, { useState, useEffect } from "react";
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { UserProvider } from "./context/user";
import useUser from "./hooks/useUser";
import Login from '../src/components/Login';
import {
  AnnualGN, AnnualSN, AudienceGN, Attic, Audience, BD, BDTraining, Botm, Bottles, BrandSalesGN, BrandSalesSN, Calendar, CampaignsGN, CampaignsSN, DropZone, Film, GNmemberReach, Highlights, Holidays, Keynotes, Negroni24, Programming, Quest, Pantry, PantryProductPage, PantryStore, Resources, ReachGN, ReachSN, SalesGN, SippersBureau, ShowPage, Sizzles, SNmembers, Test, UK, UnderTheHood, VideoMetrics, Whipshots, RTB, CelebrationLibations,
  ShoppableTV, VizioSN, VizioGN, Treasure1, Treasure2, Treasure3, Treasure4, Treasure5,

  Nov11


} from './pages/index';
import Homebase from "./components/beta/home";
import BrandPage from './pages/BrandPage';
import VideoBranding from './components/Resources/VideoBranding';
import Toolkits from "./components/Resources/ToolKits/ToolKits";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import Sidebar from '../src/components/Sidebar';
import Topbar from '../src/components/Topbar';

function App() {
  const [user, setUser] = useUser();  // Ensure this hook correctly provides the user and a way to update it
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  // CONF ////////////////////////////////////
  useEffect(() => {
    if (user?.username === "lane") {
      setIsSidebar(false);

      // Define allowed routes for "lane"
      const allowedRoutes = ["/training", "/BD"];

      // Check if the current pathname is one of the allowed routes
      if (!allowedRoutes.includes(location.pathname)) {
        navigate("/training", { replace: true });
      }
    } else if (user?.username === "sk" || user?.username === "kb") {
      setIsSidebar(true);
      if (location.pathname !== "/confidential") {
        navigate("/confidential", { replace: true }); // Navigate to /confidential if David or AJ is logged in
      }
    } else {
      setIsSidebar(true); // Show sidebar for other users
    }
  }, [user, location.pathname, navigate]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <UserProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="app">

            {/* //PUBLIC///////////////////////////////// */}
            {user && location.pathname !== "/ShoppableTV" &&
              location.pathname !== "/shoot" &&
              location.pathname !== "/treasure2" &&
              location.pathname !== "/treasure3" &&
              location.pathname !== "/treasure4" &&
              location.pathname !== "/treasure5" && isSidebar && <Sidebar isSidebar={isSidebar} user={user} />}
            <main className={location.pathname === "/ShoppableTV" ? "content-full" : "content"}>
              {user && location.pathname !== "/ShoppableTV" && <Topbar setIsSidebar={setIsSidebar} />}
              <Routes>
                {/* Public route for ShoppableTV */}
                <Route path="/ShoppableTV" element={<ShoppableTV />} />
                <Route path="/shoot" element={<Nov11 />} />
                <Route path="/treasure2" element={<Treasure2 />} />
                <Route path="/treasure3" element={<Treasure3 />} />
                <Route path="/treasure4" element={<Treasure4 />} />
                <Route path="/treasure5" element={<Treasure5 />} />

                {user ? (
                  <>
                    <Route path="/" element={<Homebase />} />
                    <Route path="/Resources" element={<Resources />} />
                    <Route path="/AnnualGN" element={<AnnualGN />} />
                    <Route path="/AnnualSN" element={<AnnualSN />} />
                    <Route path="/AudienceGN" element={<AudienceGN />} />
                    <Route path="/Audience" element={<Audience />} />
                    <Route path="/Training" element={<BDTraining />} />
                    <Route path="/BOTM" element={<Botm />} />
                    <Route path="/BrandSalesGN" element={<BrandSalesGN />} />
                    <Route path="/BrandSalesSN" element={<BrandSalesSN />} />
                    <Route path="/BD" element={<BD />} />
                    <Route path="/Bottles" element={<Bottles />} />
                    <Route path="/Calendar" element={<Calendar />} />
                    <Route path="/DropZone" element={<DropZone />} />
                    <Route path="/CampaignsGN" element={<CampaignsGN />} />
                    <Route path="/CampaignsSN" element={<CampaignsSN />} />
                    <Route path="/Keynotes" element={<Keynotes />} />
                    <Route path="/Film" element={<Film />} />
                    <Route path="/Highlights" element={<Highlights />} />
                    <Route path="/Negroni24" element={<Negroni24 />} />
                    <Route path="/Holidays" element={<Holidays />} />
                    <Route path="/Quest" element={<Quest />} />
                    <Route path="/Programming" element={<Programming />} />
                    <Route path="/Programming/:clip" element={<ShowPage />} />
                    <Route path="/SalesGN" element={<SalesGN />} />
                    <Route path="/SippersBureau" element={<SippersBureau />} />
                    <Route path="/Sizzles" element={<Sizzles />} />
                    <Route path="/SNmembers" element={<SNmembers />} />
                    <Route path="/ReachGN" element={<ReachGN />} />
                    <Route path="/ReachSN" element={<ReachSN />} />
                    <Route path="/Test" element={<Test />} />
                    <Route path="/toolkit" element={<Toolkits />} />
                    <Route path="/UK" element={<UK />} />
                    <Route path="/platformtech" element={<UnderTheHood />} />
                    <Route path="/confidential" element={< Attic />} />
                    <Route path="/videokit" element={<VideoBranding />} />
                    <Route path="/videometrics" element={<VideoMetrics />} />
                    <Route path="/Whipshots" element={<Whipshots />} />
                    <Route path="/VizioSN" element={<VizioSN />} />
                    <Route path="/VizioGN" element={<VizioGN />} />

                    {/* campaigns */}
                    <Route path="/RTB" element={<RTB />} />
                    <Route path="/CelebrationLibations" element={<CelebrationLibations />} />

                    {/* Pantry */}
                    <Route path="/pantry" element={<Pantry />} />
                    <Route path="/pantrystore" element={<PantryStore />} />
                    <Route path="/pantry/pdp/:clip" element={<PantryProductPage />} />

                    {/* dynamic */}
                    <Route path="/client/:holding" element={<BrandPage />} />

                    {/* login */}
                    <Route exact path="/login" element={<Login setUser={setUser} />} />
                  </>
                ) : (
                  <>
                    <Route exact path="/login" element={<Login setUser={setUser} />} />
                    <Route path="*" element={<Login setUser={setUser} />} />
                  </>
                )}
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </UserProvider>
    </ColorModeContext.Provider>
  );
}

export default App;