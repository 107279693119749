import React, { useEffect, useState } from "react"
import { tokens } from "../theme";
import { useTheme, TextField } from "@mui/material";
import { saveAs } from "file-saver";
import { useParams } from 'react-router-dom';

//styling
import Tally1 from "../components/visuals/tally1";
import Tally2 from "../components/visuals/tally2";
import Tally3 from "../components/visuals/tally3";

//icons
import LiquorIcon from '@mui/icons-material/Liquor';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';

//functiionality
const BrandPage = (props) => {
    //color 
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    function hexToRgba(hex, opacity) {
        hex = hex.replace('#', '');
        const bigint = parseInt(hex, 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;

        return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
    }

    //params
    const { holding } = useParams();
    // const { client } = useParams();
    console.log('holding', holding)

    //campaigns
    const [brands, setBrands] = useState([]);
    const [totalImpressions, setTotalImpressions] = useState(0);
    const [totalViews, setTotalViews] = useState(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [brandsResponse, impsResponse, viewsResponse] = await Promise.all([
                    fetch(`${process.env.REACT_APP_SERVER_URL}/campaigns/${holding}`),
                    fetch(`${process.env.REACT_APP_SERVER_URL}/campaigns/imps/${holding}`),
                    fetch(`${process.env.REACT_APP_SERVER_URL}/campaigns/views/${holding}`)
                ]);

                const [brandsJson, impsJson, viewsJson] = await Promise.all([
                    brandsResponse.json(),
                    impsResponse.json(),
                    viewsResponse.json()
                ]);

                if (brandsResponse.ok) {
                    setBrands(brandsJson);
                }

                if (impsResponse.ok) {
                    setTotalImpressions(impsJson);
                }

                if (viewsResponse.ok) {
                    setTotalViews(viewsJson);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, [holding]);

    const filterBrands = (brands, searchTerm) => {
        let results = brands;
        if (searchTerm) {
            results = results.filter((brand) => {
                const fieldValue = Object.values(brand)
                    .map((value) => (value || "").toString().toLowerCase())
                    .join(" ");
                return fieldValue.includes(searchTerm.toLowerCase());
            });
        }
        return results;
    };






    //Sales
    const [orders, setOrders] = useState([]);
    const [totalUnits, setTotalUnits] = useState([]);
    const [totalSales, setTotalSales] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [ordersResponse, unitsResponse, salesResponse] = await Promise.all([
                    fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/${holding}`),
                    fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/unitsallorders/${holding}`),
                    fetch(`${process.env.REACT_APP_SERVER_URL}/usbc/totalsales/${holding}`)
                ]);

                const [ordersData, unitsData, salesData] = await Promise.all([
                    ordersResponse.json(),
                    unitsResponse.json(),
                    salesResponse.json()
                ]);

                if (ordersResponse.ok) {
                    setOrders(ordersData);
                }

                if (unitsResponse.ok) {
                    setTotalUnits(unitsData);
                }

                if (salesResponse.ok) {
                    setTotalSales(salesData);
                }
            } catch (error) {
                console.error('Failed to fetch data:', error);
            }
        };

        fetchData();
    }, [holding]);

    //CSV
    const generateCSVContent = (data) => {
        const rows = data.map((order) => {
            return [
                order.product,
                order.total.units,
                order.total.subtotal,
                order['2024'].units,
                order[2024].subtotal,
                order['2023'].units,
                order[2023].subtotal,
                order['2022'].units,
                order[2022].subtotal,
                order['2021'].units,
                order[2021].subtotal,
                order['2020'].units,
                order[2020].subtotal,
            ].map(field => field.toString().replace(/,/g, '')); // Remove commas from fields
        });

        const header = ["Product", "All Units", "All Sales", "2024 Units", "2024 Sales", "2023 Units", "2023 Sales", "2022 Units", "2022 Sales", "2021 Units", "2021 Sales", "2020 Units", "2020 Sales"];

        const csvContent = [header, ...rows].map((row) => row.join(",")).join("\n"); // Use tab as delimiter
        return csvContent;
    };

    const handleDownloadCSV = (data) => {
        const csvContent = generateCSVContent(data);
        const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
        saveAs(blob, "sales.csv");
    };

    //search 
    const [searchTerm, setSearchTerm] = useState("");
    const [searchTerm2, setsearchTerm2] = useState("");
    const [filteredBrands, setFilteredBrands] = useState([]);

    //sort
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const handleSort = (field) => {
        if (sortField === field) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortOrder('asc');
        }
    };

    // Update the filtered shows whenever the search term or original data changes
    useEffect(() => {
        let filteredData = filterBrands(brands, searchTerm);

        if (sortField) {
            filteredData.sort((a, b) => {
                if (a[sortField] < b[sortField]) return sortOrder === 'asc' ? -1 : 1;
                if (a[sortField] > b[sortField]) return sortOrder === 'asc' ? 1 : -1;
                return 0;
            });
        }

        setFilteredBrands(filteredData);
    }, [brands, searchTerm, sortField, sortOrder]);

    //search filters
    const filterOrders = (orders, searchTerm2) => {
        if (!searchTerm2) {
            return orders;
        }

        return orders.filter((order) => {
            // Combine all fields' values into a single string and compare with the search term
            const fieldValue = Object.values(order)
                .map((value) => (value || "").toString().toLowerCase())
                .join(" ");
            return fieldValue.includes(searchTerm2.toLowerCase());
        });
    };

    return (
        <div style={{ marginLeft: 10 }}>
            <h2 style={{ color: `${colors.SKcustom[10]}` }}>{holding}</h2>

            <div className="Stackcontainer">
                <Tally1>
                    <LiquorIcon className="Shannon" />
                    <p style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', color: colors.SKcustom[17] }}>{totalUnits}</p>

                    <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>all time units sold</p>
                </Tally1>

                <Tally2>
                    <PaidOutlinedIcon className="Shannon" />

                    <h3 style={{ margin: '0', fontSize: 16, fontWeight: '300', color: colors.SKcustom[17] }}>{totalSales}</h3>

                    <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>all time sales</p>
                </Tally2>
                {/* <Tally3>
                    <div>
                        <GroupsOutlinedIcon className="Shannon" />
                        <h3 style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', fontWeight: '300', color: colors.SKcustom[17] }}>{totalImpressions.toLocaleString()}</h3>
                        <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>imps</p>
                    </div>

                    <div>
                        <OndemandVideoOutlinedIcon className="Shannon" />
                        <h3 style={{ margin: '0', fontSize: 16, textTransform: 'uppercase', fontWeight: '300', color: colors.SKcustom[17] }}>{totalViews.toLocaleString()}</h3>
                        <p style={{ margin: '0', fontSize: 11, textTransform: 'uppercase' }}>views</p>
                    </div>
                </Tally3> */}
            </div>

            {/* Campaigns */}
            {/* <TextField
                label="Search Campaigns"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                variant="standard"
                margin="normal"
                size="small"
                InputLabelProps={{
                    style: {
                        fontStyle: "italic",
                        fontSize: "10px"
                    }
                }}
            /> */}
            {/* 
            <table className="tableSK">
                <thead>
                    <tr style={{ color: colors.SKcustom[20] }}>
                        <th className="campcolS" onClick={() => handleSort('holding')}>Client <ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" onClick={() => handleSort('brand')}>Brand<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" onClick={() => handleSort('campaign')}>Campaign<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" onClick={() => handleSort('live')}>Live<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" onClick={() => handleSort('imp_total')}>Imp Total<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" onClick={() => handleSort('view_total')}>View Total<ExpandMoreOutlinedIcon style={{ fontSize: "12px" }} /></th>
                        <th className="campcolS" >Min. Imp</th>
                        <th className="campcolS" >Min. View</th>
                    </tr>
                </thead>
                <tbody style={{ color: colors.SKcustom[10] }}>
                    {filteredBrands.map((brand, index) => (
                        <tr key={brand._id}
                            style={{ backgroundColor: index % 2 === 0 ? hexToRgba(colors.SKcustom[25], 0.2) : 'transparent' }} >
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{brand.holding}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{brand.brand}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{brand.campaign}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{brand.live}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{brand.imp_total ? brand.imp_total.toLocaleString() : 'N/A'}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2) }}>{brand.view_total ? brand.view_total.toLocaleString() : 'N/A'}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2), color: colors.SKcustom[11] }}>{brand.imp_min.toLocaleString()}</td>
                            <td className="campcell" style={{ borderColor: hexToRgba(colors.SKcustom[25], 0.2), color: colors.SKcustom[11] }}>{brand.view_min.toLocaleString()}</td>
                        </tr>
                    ))}
                </tbody>
            </table> */}

            {/* Bottles */}

            <div style={{ display: "flex", alignItems: "flex-end" }}>
                <TextField
                    label="Search Bottles"
                    value={searchTerm2}
                    onChange={(e) => setsearchTerm2(e.target.value)}
                    variant="standard"
                    margin="normal"
                    size="small"
                    InputLabelProps={{
                        style: {
                            fontStyle: "italic",
                            fontSize: "10px"
                        }
                    }}
                />
                <button className="neonButton" onClick={() => handleDownloadCSV(orders)}>Download</button>
            </div>

            <table className="tableSK">
                <thead style={{ textAlign: 'left', color: `${colors.SKcustom[10]}` }}>
                    <tr style={{ color: colors.SKcustom[20] }}>
                        <th className="campcolH" >Product</th>
                        <th className="campcolH" style={{ fontWeight: "bold", color: colors.SKcustom[10], backgroundColor: hexToRgba(colors.SKcustom[30], 0.5) }} ></th>
                        <th className="campcolH" style={{ fontWeight: "bold", color: colors.SKcustom[10], backgroundColor: hexToRgba(colors.SKcustom[30], 0.5) }}>Total</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid" }}>2024</th>
                        <th className="campcolH" >Sales</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid" }}>2023</th>
                        <th className="campcolH" >Sales</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid" }}>2022</th>
                        <th className="campcolH" >Sales</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid" }}>2021</th>
                        <th className="campcolH" >Sales</th>
                        <th className="campcolH" style={{ borderLeft: "0.5px solid" }}>2020</th>
                        <th className="campcolH" >Sales</th>
                    </tr>
                </thead>

                <tbody className="table" style={{ color: `${colors.SKcustom[10]}` }}>

                    {filterOrders(orders, searchTerm2).map((order, index) => (
                        <tr key={index}>
                            <td className="cells" style={{ width: "20%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{order.product}</td>

                            <td className="cells" style={{ backgroundColor: hexToRgba(colors.SKcustom[30], 0.5), color: `${colors.SKcustom[10]}`, width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{order.total.units}</td>

                            <td className="cells" style={{ backgroundColor: hexToRgba(colors.SKcustom[30], 0.5), color: `${colors.SKcustom[10]}`, width: "7%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none" }}>{order.total.subtotal}</td>

                            <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order['2024'].units}</td>

                            <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2024].subtotal}</td>

                            <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order['2023'].units}</td>

                            <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2023].subtotal}</td>

                            <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order[2022].units}</td>

                            <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2022].subtotal}</td>

                            <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order[2021].units}</td>

                            <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2021].subtotal}</td>

                            <td className="cells" style={{ width: "5%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: "none", textAlign: "center" }}>{order[2020].units}</td>

                            <td className="cells" style={{ width: "6%", borderTop: `0.5px solid ${colors.SKcustom[0]}`, borderBottom: "none", borderLeft: "none", borderRight: `0.5px solid ${colors.SKcustom[0]}` }}>{order[2020].subtotal}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BrandPage;