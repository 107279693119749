import { React, useState } from 'react';
import { tokens } from '../theme';
import { useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import "../components/visuals/design/sk.css"


import Timeline from "../components/Resources/BDTimeline"


//execution
const BDTraining = () => {

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  function hexToRgba(hex, opacity) {
    hex = hex.replace('#', '');
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')';
  }


  const videos = [
    'https://player.vimeo.com/video/841646657?h=dae78d676f',
    'https://player.vimeo.com/video/841647508?h=801bad18cf',
  ];


  return (
    <div style={{ marginLeft: "1em", }} >
      <div>




        {/* BD */}
        < h2 style={{ marginBottom: "0.5em", color: hexToRgba(colors.SKcustom[20], 0.9), fontSize: "1.3em" }}> Lessons in Pitching </h2 >

        <div className="rowPlain">
          {videos.map((videoUrl, index) => (
            <iframe
              key={index}
              src={videoUrl}
              width="320" // Adjust the width as necessary
              height="180" // Adjust the height as necessary
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen
            ></iframe>
          ))}
        </div>
      </div>

      <Timeline />


    </div >
  );
};

export default BDTraining

